<template>
<div id="editForm">
    <vs-sidebar parent="editForm" hidden-background click-not-close position-right class="items-no-padding" v-model="active">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>Add New Promo Code</h4>
                </div>
            </div>
            <vs-divider class="mb-0" />
            <div class="vx-col w-full mb-base">
                <vx-card no-shadow>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(saveCompany)">
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full mb-2">
                                    <span>Number of Promo Codes Required</span>
                                </div>
                                <div class="vx-col w-full">
                                    <ValidationProvider name="promoCodeNumber" :rules="{ regex: /^[0-9]+$/, required: true, max:100 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" name="promoCodeNumber" v-model="currentPromo.promoCodeNumber" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full mb-2">
                                    <span>Discount Percentage</span>
                                </div>
                                <div class="vx-col w-full">
                                    <ValidationProvider name="discountPercentage" :rules="{ regex: /^[0-9.]+$/, required: true, max:10 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" name="discountPercentage" v-model="currentPromo.discountPercentage" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full mb-2">
                                    <span>Expiry Date</span>
                                </div>
                                <div class="vx-col w-full">
                                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="ExpiryDate" disabled>
                                        <flat-pickr class="w-full" aria-label="Expiry Date" name="ExpiryDate" :config="DateTimeConfig" v-model="currentPromo.expiryDate" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full mb-2">
                                    <span>Select Product</span>
                                </div>
                                <div class="vx-col w-full">
                                    <vs-select name="product" v-model="selectedPromoProduct" class="w-full">
                                        <vs-select-item key="00000000-0000-0000-0000-000000000000" value="00000000-0000-0000-0000-000000000000" text="No Product" />
                                        <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item, index) in productList" />
                                    </vs-select>
                                </div>
                            </div>
                            <vs-divider />
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <vs-button class="mr-3 mb-2" @click.prevent="saveUser">Submit</vs-button>
                                    <vs-button class="mr-3 mb-2" color="dark" @click="closeWindow">Cancel</vs-button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </vx-card>
            </div>
        </div>
    </vs-sidebar>
</div>
</template>

<script>
import {
    promoData
} from '../../store/api/promo';
import {
    productData
} from "@/store/api/product";
import {
    customMessages
} from '../../filters/validationmessage'

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
    props: {
        openAddWindow: {
            type: Boolean,
            required: true,
            default: false,
        },
        selectedUser: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            currentPromo: {},
            active: {},
            customMessages,
            DateTimeConfig: {
                wrap: true,
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false,
            },
            selectedPromoProduct: '00000000-0000-0000-0000-000000000000',
            productList: [],
        };
    },
    components: {
        flatPickr
    },
    watch: {
        openAddWindow: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.active = newValue;
            }
        },
        selectedUser: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.currentPromo = newValue;
                if (!this.currentPromo) {
                    this.selectedPromoProduct = this.currentPromo.productId;
                }
            }
        }
    },
    async created() {
        await this.loadProduct();
    },
    methods: {
        async loadProduct() {
            this.productList = await productData.SearchProducts();
        },
        async saveUser() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            this.currentPromo.productId = this.selectedPromoProduct;
            let apiResult = await promoData.createPromoCode(this.currentPromo);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Promo Code added",
                color: "success",
                position: "top-center"
            });

            this.$emit("loadPromoCode");
        },
        closeWindow() {
            this.$emit("loadPromoCode");
        }
    }
}
</script>

<style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 30vw;
        margin: 0px;
        max-height: 100vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}
</style>
