var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"editForm"}},[_c('vs-sidebar',{staticClass:"items-no-padding",attrs:{"parent":"editForm","hidden-background":"","click-not-close":"","position-right":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"h-full"},[_c('div',{staticClass:"customizer-header mt-6 flex items-center justify-between px-6"},[_c('div',[_c('h4',[_vm._v("Add New Promo Code")])])]),_c('vs-divider',{staticClass:"mb-0"}),_c('div',{staticClass:"vx-col w-full mb-base"},[_c('vx-card',{attrs:{"no-shadow":""}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveCompany)}}},[_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full mb-2"},[_c('span',[_vm._v("Number of Promo Codes Required")])]),_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{attrs:{"name":"promoCodeNumber","rules":{ regex: /^[0-9]+$/, required: true, max:100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"promoCodeNumber"},model:{value:(_vm.currentPromo.promoCodeNumber),callback:function ($$v) {_vm.$set(_vm.currentPromo, "promoCodeNumber", $$v)},expression:"currentPromo.promoCodeNumber"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full mb-2"},[_c('span',[_vm._v("Discount Percentage")])]),_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{attrs:{"name":"discountPercentage","rules":{ regex: /^[0-9.]+$/, required: true, max:10 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"discountPercentage"},model:{value:(_vm.currentPromo.discountPercentage),callback:function ($$v) {_vm.$set(_vm.currentPromo, "discountPercentage", $$v)},expression:"currentPromo.discountPercentage"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full mb-2"},[_c('span',[_vm._v("Expiry Date")])]),_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ required: true },"custom-messages":_vm.customMessages,"name":"ExpiryDate","disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"w-full",attrs:{"aria-label":"Expiry Date","name":"ExpiryDate","config":_vm.DateTimeConfig},model:{value:(_vm.currentPromo.expiryDate),callback:function ($$v) {_vm.$set(_vm.currentPromo, "expiryDate", $$v)},expression:"currentPromo.expiryDate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full mb-2"},[_c('span',[_vm._v("Select Product")])]),_c('div',{staticClass:"vx-col w-full"},[_c('vs-select',{staticClass:"w-full",attrs:{"name":"product"},model:{value:(_vm.selectedPromoProduct),callback:function ($$v) {_vm.selectedPromoProduct=$$v},expression:"selectedPromoProduct"}},[_c('vs-select-item',{key:"00000000-0000-0000-0000-000000000000",attrs:{"value":"00000000-0000-0000-0000-000000000000","text":"No Product"}}),_vm._l((_vm.productList),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.name}})})],2)],1)]),_c('vs-divider'),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_vm._v("Submit")]),_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"color":"dark"},on:{"click":_vm.closeWindow}},[_vm._v("Cancel")])],1)])],1)]}}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }